import { ButtonHTMLAttributes, PropsWithChildren } from "react";

import cx from "classix";

export const Button = ({ children, ...props }: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>) => {
  return (
    <button
      className={cx(
        "bg-bluePrimary active:bg-bluePressed text-black py-3 w-full rounded-3xl flex justify-center disabled:bg-blueDisabled",
      )}
      {...props}
    >
      {children}
    </button>
  );
};
